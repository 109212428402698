import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Button, Box, Heading } from "grommet"
import {
  Github as GithubIcon,
  ShareRounded as OpenIcon,
  LinkPrevious as BackIcon,
} from "grommet-icons"
import { navigate } from "gatsby"
import styled from "styled-components"

const FrameContainer = styled(Box)`
  height: 100vh;
  width: 100vw;
  border-radius: 30px;
  overflow: hidden;
`

const Frame = styled.iframe`
  width: 100%;
  height: 200%;
  border: 0;
`

const ProjectPage = ({
  pageContext: { name, customName, url, homepageUrl, projectUrl },
}) => {
  return (
    <Layout>
      <Seo title={customName || name} />
      <Heading alignSelf="center" margin={{ top: "none" }}>
        {customName || name}
      </Heading>
      <Box direction="row" align="center" justify="between">
        <BackIcon
          size="large"
          onClick={() => navigate(-1)}
          style={{ cursor: "pointer" }}
        />
        <Box direction="row" justify="center" gap="small" margin="medium">
          <Button
            label={
              <Box align="center" justify="center" direction="row" gap="xsmall">
                <GithubIcon /> github
              </Box>
            }
            href={url}
            target="_blank"
          />
          {homepageUrl && (
            <Button
              href={homepageUrl}
              label={
                <Box
                  align="center"
                  justify="center"
                  direction="row"
                  gap="xsmall"
                >
                  <OpenIcon /> check it out
                </Box>
              }
              target="_blank"
            />
          )}
        </Box>
      </Box>
      <FrameContainer>
        <Frame src={projectUrl || homepageUrl || url} />
      </FrameContainer>
    </Layout>
  )
}

export default ProjectPage
